import React from "react";

const TermsCondition = () => {
  return (
    <div className="max-w-[1164px] mx-auto container px-4 py-12">
      <div className="max-w-[820px]">
        <h2 className="text-3xl sm:text-4xl my-3 font-semibold">
          Terms and Conditions
        </h2>
        <p className="text-sm sm:text-base sm:mb-4 mb-3">
          Welcome to AK Technical. By accessing or using our website and
          services, you agree to comply with and be bound by the following Terms
          and Conditions. Please read them carefully before using our platform.
          If you do not agree to these terms, please discontinue use
          immediately.
        </p>

        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          1. Acceptance of Terms
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          By accessing our platform, you agree to be bound by these Terms and
          Conditions and any other terms and policies referenced herein. [Your
          Company Name] reserves the right to change these terms at any time
          without prior notice. Continued use of the platform after such changes
          constitutes your acceptance of the new terms.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          2. Eligibility
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          To use our services, you must be at least 18 years old or have legal
          parental or guardian consent if you are under 18. By using our
          platform, you represent and warrant that you have the legal capacity
          to enter into this agreement and comply with these Terms and
          Conditions.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          3. User Accounts
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          When you register for an account, you agree to: Provide accurate and
          complete information. Keep your account details secure and
          confidential. Notify us immediately of any unauthorized use of your
          account. Be responsible for all activities that occur under your
          account.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          4. Use of the Platform
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          You agree to use the platform only for lawful purposes and in
          accordance with these Terms and Conditions. You may not: Use the
          platform in any way that violates any applicable federal, state,
          local, or international law. Engage in unauthorized access to or
          interference with the platform, its servers, or any associated
          systems. Attempt to harvest, scrape, or collect data from the platform
          using automated means without prior consent. Use the platform for any
          fraudulent or deceptive practices, including the submission of
          plagiarized or false assignments.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          5. Intellectual Property Rights
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          All content, design, graphics, logos, and text on the platform are the
          intellectual property of AK Technical or our licensors. You are
          granted a limited, non-exclusive, non-transferable license to access
          and use the platform for personal or educational purposes. You agree
          not to copy, reproduce, distribute, or create derivative works from
          any part of the platform without express written permission from [Your
          Company Name].
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          6. Assignment Submissions and Content
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          Users may submit assignments, content, or data via the platform. You
          retain all rights to the content you create and submit, but by using
          our platform, you grant us a worldwide, non-exclusive, royalty-free
          license to use, display, and store your submitted content for the
          purpose of operating and improving our services.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          7. Fees and Payment
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          If the platform offers paid services, you agree to pay all applicable
          fees in connection with the use of those services. All payments are
          due as specified at the time of purchase. Fees are non-refundable
          unless expressly stated in our refund policy.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          8. Third-Party Services
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          Our platform may contain links to third-party websites or services
          that are not owned or controlled by AK Technical. We do not assume
          responsibility for the content, privacy policies, or practices of
          third-party websites. You acknowledge and agree that we are not liable
          for any damages or losses caused by your use of third-party services
          or websites.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          9. Termination
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          We reserve the right to suspend or terminate your account and access
          to the platform at our discretion if we determine that you have
          violated these Terms and Conditions or engaged in behavior that is
          harmful to our platform or users. Upon termination, your right to use
          the platform will immediately cease.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          10. Limitation of Liability
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          To the maximum extent permitted by law, AK Technical and its
          affiliates shall not be liable for any direct, indirect, incidental,
          special, or consequential damages arising out of your use or inability
          to use the platform. This includes, but is not limited to, damages for
          loss of profits, data, or other intangible losses, even if we have
          been advised of the possibility of such damages.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          11. Disclaimer of Warranties
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          Our platform is provided on an "as is" and "as available" basis. We
          make no warranties or representations, express or implied, regarding
          the operation of the platform, including the accuracy, reliability, or
          availability of the services. You use the platform at your own risk.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          12. Indemnification
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          You agree to indemnify, defend, and hold harmless AK Technical, its
          affiliates, and their respective officers, directors, employees, and
          agents from and against any claims, liabilities, damages, losses, or
          expenses, including legal fees, arising out of your use of the
          platform or violation of these Terms and Conditions.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          13. Governing Law
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          These Terms and Conditions and any disputes arising from your use of
          the platform are governed by and construed in accordance with the laws
          of [Insert Jurisdiction]. Any legal action or proceeding related to
          these terms shall be brought exclusively in the courts located within
          [Insert Jurisdiction].
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          14. Severability
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          If any provision of these Terms and Conditions is found to be invalid
          or unenforceable, the remaining provisions will continue in full force
          and effect.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          15. Changes to the Terms
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          We reserve the right to modify or replace these Terms and Conditions
          at any time. We will notify you of any significant changes by posting
          the new terms on our website. It is your responsibility to review the
          Terms and Conditions periodically for updates. Continued use of the
          platform after any such changes constitutes acceptance of the new
          terms.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          16. Contact Us
        </h2>
        <p className="text-sm sm:text-base mb-1">
          If you have any questions or concerns about these Terms and
          Conditions, please contact us at:
        </p>
        <p className="text-sm sm:text-base text-black font-bold mb-3 sm:mb-4">
          <a
            href="https://www.devguide.info/"
            target="_blank"
            rel="noopener noreferrer"
          >
            AK Technical
          </a>
          <br />
          Email:
          <a href="mailto:ak.technical.co@gmail.com" target="_blank">
            {" "}
            ak.technical.co@gmail.com
          </a>
          <br />
          Phone: <a href="tel:+917015371814">70153-71814</a> <br />
        </p>
      </div>
    </div>
  );
};

export default TermsCondition;
