import React from "react";

const CustomButton = ({ children, className, url }) => {
  const buttonClasses = `bg-[#023E48] text-white text-base sm:py-4 px-3 sm:px-5 h-[40px] sm:h-[50px] flex items-center !leading-[100%] border border-transparent hover:text-[#023E48] rounded-full hover:bg-white hover:border-[#023E48] transition-all duration-300 ease-in-out ${className}`;

  return url ? (
    <a href={url} className={buttonClasses}>
      {children}
    </a>
  ) : (
    <button className={buttonClasses}>{children}</button>
  );
};

export default CustomButton;
