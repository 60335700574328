import australiaFlag from "../../assets/images/png/australia-flag.avif";
import canadaflag from "../../assets/images/png/canada-flag.avif";
import usaflag from "../../assets/images/png/usa-flag.avif";
import indiaflag from "../../assets/images/png/india-flag.webp";
// HEADER LIST
export const NavLinkList = [
  {
    url: "/",
    title: "Home",
  },
  {
    url: "/#about-us",
    title: "About",
  },
  {
    url: "/#contact-us",
    title: "Contact-Us",
  },
  {
    url: "/#faq",
    title: "Faq's",
  },
];
// FAQ LIST
export const FaqsList = [
  {
    title:
      "Q1. How does your platform customize online assignment solutions for colleges and universities?",
    description:
      "Our platform tailors assignment processes using adaptive learning technologies, providing personalized solutions that cater to the specific needs of each institution and their students.",
  },
  {
    title: "Q2. What kind of real-time analytics does your platform offer?",
    description:
      "We provide real-time analytics on student performance, assignment progress, and learning trends, allowing educators to make informed decisions and address learning gaps effectively.",
  },
  {
    title: "Q3. How does the platform ensure secure testing environments?",
    description:
      "Our platform uses advanced security measures such as proctoring, identity verification, and restricted browser environments to maintain the integrity of online assessments.",
  },
  {
    title:
      "Q4. How can educators use your platform to improve student outcomes?",
    description:
      "With features like adaptive learning and personalized feedback, our platform helps educators identify areas of improvement for students and offer targeted interventions to enhance learning.",
  },
  {
    title:
      "Q5.Can the platform integrate with existing learning management systems (LMS)?",
    description:
      "Yes, our platform seamlessly integrates with popular LMS platforms, ensuring a smooth transition and enhancing the overall assignment management experience.",
  },
];
export const CountriesList = [
  {
    image: indiaflag,
    title: "India",
  },
  {
    image: australiaFlag,
    title: "Austraila",
  },
  {
    image: canadaflag,
    title: "Canada",
  },
  {
    image: usaflag,
    title: "USA",
  },
  {
    image: indiaflag,
    title: "India",
  },
  {
    image: australiaFlag,
    title: "Austraila",
  },
  {
    image: canadaflag,
    title: "Canada",
  },
  {
    image: usaflag,
    title: "USA",
  },
];
export const aboutUsList = [
  {
    title: "Transforming Online Assignments",
    description:
      "At AK Technical, we are committed to revolutionizing how colleges and universities manage assignments. Our mission is to provide institutions with innovative, secure, and efficient tools that enhance the assignment process and unlock student potential.",
  },
  {
    title: "Cutting-Edge Assignment Tools",
    description:
      "We develop state-of-the-art online assignment solutions that ensure accuracy, efficiency, and ease of use. Our tools allow educators to design and manage assignments seamlessly, ensuring assessments are relevant and meaningful for students.",
  },
  {
    title: "Personalized Learning Solutions",
    description:
      "Our platform integrates adaptive learning technologies to tailor assignments to individual student needs. By leveraging real-time data, we help educators create assignments that address learning gaps and support each student's unique academic journey.",
  },
];
