import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
import PrivacyPolicy from "./components/common/PrivacyPolicy";
import TermsCondition from "./components/common/TermsCondition";
import Home from "./components/home/Home";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
