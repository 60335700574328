import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { CountriesList } from "./common/Helper";
import CustomHeading from "./common/CustomHeading";
const CountriesSlider = () => {
  return (
    <div className="relative after:absolute after:top-0 after:start-0 after:h-full after:w-full after:bg-[#023E48] after:rounded-xl after:-z-10 bg-hero bg-cover bg-no-repeat mx-3 sm:mx-4 rounded-xl mb-20">
      <div className="container max-w-[1164px] mx-auto ps-3 pe-0 lg:px-3 py-12 md:py-16 lg:py-20">
        <CustomHeading className="text-center mb-6 text-white">
          Our{" "}
          <span className="bg-white rounded-lg text-[#023E48] px-2">
            Patners
          </span>
        </CustomHeading>
        <Swiper
          spaceBetween={16}
          slidesPerView={1.4}
          loop={true}
          speed={4000}
          autoplay={{
            delay: 0,
          }}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            1050: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 2.5,
              spaceBetween: 24,
            },
            600: {
              slidesPerView: 1.8,
            },
            500: {
              slidesPerView: 1.5,
            },
          }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {CountriesList.map((obj, index) => {
            return (
              <SwiperSlide
                key={index}
                className="border border-white rounded-xl relative group"
              >
                <img
                  src={obj.image}
                  alt="image"
                  width={450}
                  height={300}
                  className="w-full h-[150px] sm:h-[192px] object-cover rounded-xl"
                />
                <p className="absolute w-full py-3 -bottom-full group-hover:bottom-0 start-0 bg-black bg-opacity-40 rounded-es-xl rounded-ee-xl text-center text-white font-bold transition-all duration-300 ease-in-out">
                  {obj.title}
                </p>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default CountriesSlider;
