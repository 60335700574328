import emailjs from "@emailjs/browser";
import React, { useReducer, useRef } from "react";
import CustomButton from "./common/CustomButton";
import CustomHeading from "./common/CustomHeading";
import CustomInput from "./common/CustomInput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
  id_name: "",
  id_last_name: "",
  id_email: "",
  id_number: "",
  id_country: "",
  id_date: "",
  id_course: "",
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "RESET_FORM":
      return initialState;
    default:
      return state;
  }
};

const ContactUs = () => {
  const [state, dispatch] = useReducer(formReducer, initialState);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_gsbycx9",
        "template_2qza03e",
        form.current,
        "tCamtgC5QUBf0VGun"
      )
      .then(
        (result) => {
          toast.success("Form submitted successfully!", {
            position: "bottom-right",
          });
          dispatch({ type: "RESET_FORM" });
        },
        (error) => {
          console.log(error.text);
          toast.error("Failed to send the message, please try again.");
        }
      );
  };

  const handleChange = (e) => {
    dispatch({
      type: "SET_FIELD",
      field: e.target.name,
      value: e.target.value,
    });
  };
  return (
    <div className="relative after:absolute after:top-0 after:start-0 after:h-full after:w-full after:bg-[#023E48] after:rounded-xl after:-z-10 bg-hero bg-cover bg-no-repeat mx-3 sm:mx-4 rounded-xl py-12 md:py-16 lg:py-20">
      <div className="absolute -top-10 left-0" id="contact-us"></div>
      <ToastContainer />
      <div className="max-w-[1164px] px-3 sm:px-4 md:px-7 container mx-auto">
        <CustomHeading className="text-center mb-6 !text-[#FEFEFE]">
          <span className="bg-white rounded-lg text-[#023E48] px-2">
            Contact
          </span>
          &nbsp;Us
        </CustomHeading>
        <div>
          <div>
            <form ref={form} onSubmit={sendEmail}>
              <div className="flex flex-wrap items-center justify-between">
                <CustomInput
                  parentClassName="w-full sm:w-[48%]"
                  label="First Name"
                  id="name"
                  name="id_name"
                  htmlFor="name"
                  placeholder="First Name"
                  type="text"
                  value={state.id_name}
                  onChange={handleChange}
                />
                <CustomInput
                  parentClassName="w-full sm:w-[48%]"
                  label="Last Name"
                  id="last_name"
                  name="id_last_name"
                  htmlFor="last_name"
                  placeholder="Last Name"
                  type="text"
                  value={state.id_last_name}
                  onChange={handleChange}
                />
                <CustomInput
                  parentClassName="w-full sm:w-[48%]"
                  label="Email"
                  id="email"
                  name="id_email"
                  htmlFor="email"
                  placeholder="Email"
                  type="email"
                  value={state.id_email}
                  onChange={handleChange}
                />
                <CustomInput
                  parentClassName="w-full sm:w-[48%]"
                  label="WhatsApp Number"
                  id="number"
                  name="id_number"
                  htmlFor="number"
                  placeholder="WhatsApp Number"
                  type="number"
                  value={state.id_number}
                  onChange={handleChange}
                />
                <CustomInput
                  parentClassName="w-full sm:w-[48%]"
                  label="Country"
                  id="country"
                  name="id_country"
                  htmlFor="country"
                  placeholder="Country"
                  type="text"
                  value={state.id_country}
                  onChange={handleChange}
                />
                <CustomInput
                  parentClassName="w-full sm:w-[48%]"
                  label="Over Due Date"
                  id="date"
                  htmlFor="date"
                  name="id_date"
                  placeholder="Over Due Date"
                  type="date"
                  value={state.id_date}
                  onChange={handleChange}
                />
                <CustomInput
                  parentClassName="w-full sm:w-[48%]"
                  label="Course Name"
                  id="course"
                  htmlFor="course"
                  name="id_course"
                  placeholder="Course Name"
                  type="text"
                  value={state.id_course}
                  onChange={handleChange}
                />
              </div>
              <CustomButton
                type="submit"
                className="mt-6 !bg-white !text-[#023E48] hover:!bg-[#023E48] hover:border-white hover:!text-white"
              >
                Submit Now
              </CustomButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
