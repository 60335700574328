import React from "react";

const CustomInput = ({
  label,
  htmlFor,
  placeholder,
  type,
  id,
  name,
  value,
  onChange,
  parentClassName,
}) => {
  return (
    <div
      className={`flex flex-col gap-2 justify-center mt-4 ${parentClassName}`}
    >
      <label htmlFor={htmlFor} className="text-[#FEFEFE] text-opacity-60">
        {label}
      </label>
      <input
        required
        id={id}
        type={type}
        name={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        className="border border-[#FEFEFE1F] bg-[#0F4851] rounded-lg h-[44px] md:h-[50px] font-normal text-base text-[#FEFEFE] text-opacity-70 px-4 outline-none w-full date-input"
      />
    </div>
  );
};

export default CustomInput;
