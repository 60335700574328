import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-[1164px] mx-auto px-4 container py-12">
      <div className="max-w-[820px]">
        <h2 className="text-2xl sm:text-4xl my-3 font-semibold">
          Privacy Policy
        </h2>
        <p className="text-sm sm:text-base sm:mb-4 mb-3">
          At AK Technical, we are committed to protecting the privacy and
          security of our users. This Privacy Policy explains how we collect,
          use, disclose, and safeguard your information when you visit our
          website or use our services. By accessing or using our platform, you
          agree to this Privacy Policy. If you do not agree, please discontinue
          use immediately.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          1. Information We Collect
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          We collect several types of information to provide and improve our
          services: a. Personal Information: This includes data that identifies
          you personally, such as your name, email address, phone number, and
          institution details when you register on our platform or subscribe to
          our services. b. Non-Personal Information: This includes data that
          does not identify you personally, such as your browser type, device
          type, IP address, and information about your interaction with our
          website, like the pages you visit and the features you use. c.
          Assignment Data: We may collect data related to assignments, such as
          assignment questions, submission details, grades, and feedback from
          instructors.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          2. How We Use Your Information
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          We use the information we collect in various ways, including to:
          Provide, operate, and maintain our platform and services. Manage user
          accounts, and facilitate assignment creation, submissions, and
          grading. Personalize your experience by analyzing usage patterns and
          preferences. Improve our services through data analysis and customer
          feedback. Communicate with you about updates, promotions, or customer
          service requests. Ensure compliance with legal and regulatory
          requirements.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          3. How We Share Your Information
        </h2>
        <p className="text-sm sm:text-base mb-1.5 sm:mb-2">
          We only share your information under the following circumstances:
        </p>
        <p className="text-sm sm:text-base mb-1.5 sm:mb-2">
          <span className="font-bold">a. Service Providers: </span>
          We may share information with third-party vendors and service
          providers who assist us in operating our platform, processing
          transactions, or performing services on our behalf.
        </p>
        <p className="text-sm sm:text-base mb-1.5 sm:mb-2">
          <span className="font-bold">b. Legal Requirements: </span>
          We may disclose your information if required to do so by law or in
          response to valid requests by public authorities (e.g., a court or
          government agency).
        </p>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          <span className="font-bold">c. Business Transfers: </span>
          In the event of a merger, acquisition, or sale of all or a portion of
          our business, we may transfer your information to the successor
          organization.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          4. Data Security
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          We prioritize the security of your data. We employ appropriate
          technical and organizational measures to protect your personal
          information against unauthorized access, alteration, disclosure, or
          destruction. However, please note that no method of transmission over
          the internet is 100% secure, and we cannot guarantee the absolute
          security of your information.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          5. Your Data Rights
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          Depending on your location, you may have the following rights
          regarding your personal information:
        </p>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          Access: You have the right to request access to the personal
          information we hold about you. Correction: You can request correction
          of inaccurate or incomplete information. Deletion: You can request
          that we delete your personal information, subject to legal
          obligations. Opt-out: You have the right to opt-out of receiving
          marketing communications from us. To exercise any of these rights,
          please contact us at [Insert Contact Email].
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          6. Cookies and Tracking Technologies
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          We use cookies and similar technologies to improve your experience on
          our website. Cookies are small text files placed on your device to
          store data about your browsing activity. You can control the use of
          cookies through your browser settings, but disabling cookies may
          affect the functionality of our website.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          7. Third-Party Links
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          Our website may contain links to third-party websites. We are not
          responsible for the privacy practices of these websites. We encourage
          you to review the privacy policies of any third-party sites you visit.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          8. Children’s Privacy
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          Our services are not directed to individuals under the age of 13, and
          we do not knowingly collect personal information from children under
          13. If we learn that we have collected personal data from a child
          under 13 without verification of parental consent, we will delete that
          information.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          9. Changes to This Privacy Policy
        </h2>
        <p className="text-sm sm:text-base mb-3 sm:mb-4">
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or legal requirements. We will notify you of any
          significant changes by posting the updated policy on our website with
          a new effective date. Your continued use of the platform after such
          changes constitutes your acceptance of the updated Privacy Policy.
        </p>
        <h2 className="mb-1.5 sm:mb-2 text-lg sm:text-xl font-semibold">
          10. Contact Us
        </h2>
        <p className="text-sm sm:text-base mb-1">
          If you have any questions or concerns about this Privacy Policy,
          please contact us at:
        </p>
        <p className="text-sm sm:text-base text-black font-bold mb-3 sm:mb-4">
          <a
            href="https://www.devguide.info/"
            target="_blank"
            rel="noopener noreferrer"
          >
            AK Technical
          </a>
          <br />
          Email:
          <a href="mailto:ak.technical.co@gmail.com" target="_blank">
            {" "}
            ak.technical.co@gmail.com
          </a>
          <br />
          Phone: <a href="tel:+917015371814">70153-71814</a> <br />
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
