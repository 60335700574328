import React from "react";
import facebook from "../../assets/images/svg/facebook.svg";
import instagram from "../../assets/images/svg/instagram.svg";
import twitter from "../../assets/images/svg/twitter.svg";
import logo from "../../assets/images/svg/ak-logo-footer.svg";
const Footer = () => {
  const currentYear = new Date().getFullYear();
  const phoneNumber = "+917015371814";
  const message = "Hello, I would like to know more about your services!";
  const handleWhatsAppClick = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank"); // Open WhatsApp in a new tab or app
  };
  return (
    <div className="bg-[#020202]">
      <div className="container max-w-[1164px] px-3 mx-auto flex justify-between flex-wrap py-12 gap-y-9">
        <div className="w-full sm:w-9/12 lg:w-5/12">
          <a
            href="/"
            rel="noopener noreferrer"
            aria-label="ak-logo"
            className="text-white text-xl sm:text-2xl inline-block"
          >
            <img src={logo} alt="logo" width={80} />
          </a>
          <p className="text-white opacity-70 !leading-[150%] mt-3 text-sm max-w-[380px]">
            Empowering students and educators with tailored online assignment
            solutions. Our platform simplifies the submission and grading
            process, providing real-time feedback, progress tracking, and secure
            environments to help students excel and bridge learning gaps
            effectively.
          </p>
        </div>
        <div className="w-4/12 lg:w-2/12 text-white">
          <ul className="flex flex-col gap-3 lg:gap-4">
            <li>Quick Links</li>
            <li>
              <a
                rel="noopener noreferrer"
                aria-label="links"
                href="/"
                className="text-white opacity-70 hover:opacity-100 transition-all ease-in-out duration-300"
              >
                Home
              </a>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                aria-label="links"
                href="/#about-us"
                className="text-white opacity-70 hover:opacity-100 transition-all ease-in-out duration-300"
              >
                About Us
              </a>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                aria-label="links"
                href="/#contact-us"
                className="text-white opacity-70 hover:opacity-100 transition-all ease-in-out duration-300"
              >
                Contact Us
              </a>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                aria-label="links"
                href="/#faq"
                className="text-white opacity-70 hover:opacity-100 transition-all ease-in-out duration-300"
              >
                Faq's
              </a>
            </li>
          </ul>
        </div>
        <div className="4/12 lg:w-2/12 text-white">
          <ul className="flex flex-col gap-3 lg:gap-4">
            <li>Legal</li>
            <li>
              <a
                rel="noopener noreferrer"
                aria-label="links"
                href="/privacy-policy"
                className="text-white opacity-70 hover:opacity-100 transition-all ease-in-out duration-300"
              >
                Privacy policy
              </a>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                aria-label="links"
                href="/terms-condition"
                className="text-white opacity-70 hover:opacity-100 transition-all ease-in-out duration-300"
              >
                Terms & Conditions
              </a>
            </li>
          </ul>
        </div>
        <div className="4/12 lg:w-3/12 ">
          <p className="text-white text-base">Join our community</p>
          <div className="flex gap-3 mt-3 lg:mt-4">
            <a
              href="https://instagram.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="links"
              className="hover:scale-[1.07] transition-all duration-300 ease-in-out"
            >
              <img
                src={instagram}
                alt="instagram"
                width={40}
                height={40}
                className="size-8 md:size-10"
              />
            </a>
            <a
              href="https://www.facebook.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="links"
              className="hover:scale-[1.07] transition-all duration-300 ease-in-out"
            >
              <img
                src={facebook}
                alt="facebook"
                width={40}
                height={40}
                className="size-8 md:size-10"
              />
            </a>
            <a
              href="https://www.twitter.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="links"
              className="hover:scale-[1.07] transition-all duration-300 ease-in-out"
            >
              <img
                src={twitter}
                alt="twitter"
                width={40}
                height={40}
                className="size-8 md:size-10"
              />
            </a>
          </div>
          <ul className="flex flex-col gap-2 mt-4 lg:mt-6">
            <li className="text-white text-base">Contact Us</li>
            <li>
              <button
                onClick={handleWhatsAppClick}
                className="text-white opacity-70 hover:opacity-100 transition-all ease-in-out duration-300"
              >
                +91 7015371814
              </button>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                aria-label="links"
                target="_blank"
                href="mailto:ak.technical.co@gmail.com"
                className="text-white opacity-70 hover:opacity-100 transition-all ease-in-out duration-300"
              >
                ak.technical.co@gmail.com
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="border-t border-t-white py-5 border-opacity-40 text-center text-sm md:text-base text-white">
        Copyright @{currentYear} devguide.info. All Right reserved
      </div>
    </div>
  );
};

export default Footer;
